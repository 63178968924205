<template>
  <div class="list-info" id="watermark">

    <van-popup v-model="control.showRegistryType" position="bottom" @click-overlay="overlay">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="registryTypeList"
          value-key="label"
          @confirm="onConfirmReg"
          @cancel="onCancelReg"
      />
    </van-popup>
    <van-popup v-model="control.showtimeType" position="bottom" @click-overlay="overlay">
      <van-datetime-picker
          v-model="startTime"
          type="date"
          title="开始时间"
          :minDate="minDate"
          @confirm="onConTime"
          @cancel="onCanceTime"
      />
    </van-popup>
    <van-popup v-model="control.showtimeEndType" position="bottom" @click-overlay="overlay">
      <van-datetime-picker
          v-model="endTime"
          type="date"
          title="结束时间"
          :minDate="endDate"
          @confirm="onConTime1"
          @cancel="onCanceTime1"
      />
    </van-popup>
    <div class="list-search">
      <div class="searchValue">
        <input v-model="dataForm.title" placeholder="请输入协议标题" @keyup.enter="searchConfirm" type="search"
            value="搜索" class="search-content"/>
        <img class="search-icon" :src="require('@/assets/img/search.png')" />
        <van-icon v-if="dataForm.title" class="clear-btn" @click="onCancel" name="clear" color="#999" size="16" />
      </div>
    </div>
    <div>
      <div class="filterBar">
        <van-row>
          <van-col span="12">
            <p @click="control.showRegistryType = !control.showRegistryType"
               :style="{color:control.showRegistryType?'#387FF5' : '#666666' } ">
              {{ show.registryType }}
              <img :src="require(`@/assets/img/${control.showRegistryType?'pull-down-select' :'pull-down'}.png`)" alt=""
                   class="pull-icon">
            </p>
          </van-col>
          <van-col span="12">
            <p @click="control.showtimeType = !control.showtimeType"
               :style="{color:control.showtimeType?'#387FF5' : '#666666' } ">
              {{ show.time }}
              <img :src="require(`@/assets/img/${control.showtimeType?'pull-down-select' :'pull-down'}.png`)" alt=""
                   class="pull-icon">
            </p>
          </van-col>
        </van-row>
      </div>


    </div>
    <div class="cont">
      <van-list v-model="loading" :finished="control.haveMore" finished-text="没有更多了" @load="getDataList"
                v-if="dataList.length > 0" offset="10">
        <div class="totalCount" v-show="DataPageLoading">共有&nbsp;<span>{{ totalNum }}</span>&nbsp;条数据</div>
        <div class="top"  v-for="(item,index) in dataList" @click.stop="info(item)" :key="item.id">
          <div class="content"  >
            <div class="orgname">
              <span class="span1">{{item.subareaName}}-{{item.houseFullName}}</span><span class="span2" :style="{background: item.isSign==0?'rgba(43,143,50,0.1)': 'rgba(233,86,75,0.1)' , color:item.isSign==0?'#2B8F32':'#E9564B'}">{{item.isSign==0?'已签':'未签'}}</span></div>
            <van-divider/>
            <div class="user user1">
              <span class="QYtitle">{{item.contractName||'未知'}}</span>
            </div>
            <div class="user">
              <span>创建人:&nbsp;<span class="phone">{{item.createUserName||'未知'}}</span></span>
              <van-icon name="arrow" color="#666" class="bottom-icon" :style="{'top':'42%'}"/>
            </div>
          </div>
          <van-divider/>
          <div class="button-box">
            <span class="createtime">创建时间：{{item.createTime||'未知'}}</span>
            <span class="reminder" v-if="item.endDays>0&&item.endDays<=3&&item.isSign==1">还有{{item.endDays}}天截止</span>
            <span class="reminder" v-if="item.endDays<=0&&item.isSign==1">签约已截止</span>
          </div>
        </div>
      </van-list>
      <van-empty description="没有数据哦" v-if="dataList.length == 0"/>
    </div>
  </div>

</template>

<script>
import {formatterDate} from "@/utils/utils";
import topBar from '@/components/topBar/topBar'

var that

export default {
  name: "userResIndex",
  components: {
    topBar,

  },
  data() {
    return {
      startTime:'',
      endTime:'',
      minDate: new Date(2022, 0, 1),
      endDate:'',
      DataPageLoading: false,
      dataList: [],
      totalNum: 0,
      dataForm: {
        userId:this.$globalData.userInfo.userId,
        startTime:"",//起始时间
        endTime:"",//截至时间
        title: '',
        page: 0,
        limit: this.$globalData.limit,
        isSign: '', //签约状态
      },
      loading: false,
      show: {
        registryType: '签约状态',
        time: '签约时间'
      },
      control: {
        haveMore: false,
        showRegistryType: false,
        showtimeType: false, //起始时间
        showtimeEndType:false,

      },
      registryTypeList: [{label: '已签约', value: 0}, {label: '未签约', value: 1}]


    }
  },

  created() {
    that = this
    this.dataForm.page=0
    this.getDataList()
  },
  activated() {
    if (this.$route.query.searchInfo) {
    }
  },
  methods: {
    // formatter(type, val) {
    //   if (type === 'year') {
    //     return `${val}年`;
    //   } else if (type === 'month') {
    //     return `${val}月`;
    //   }
    //   return val;
    // },
    searchConfirm() {
      this.dataForm.page=0
      this.dataList = [];
      that.control.haveMore = false;
      document.activeElement.blur()
      this.getDataList()
    },
    onCancel() {
      this.dataForm.page=0
      this.dataList = [];
      that.control.haveMore = false;
      this.dataForm.title = "";
      this.getDataList();
    },
    overlay() {
      //关闭所有弹窗
    },
    //签约状态
    onConfirmReg(e) {
      this.show.registryType = e.label
      this.dataForm.isSign = e.value
      this.control.showRegistryType = false
      this.dataForm.page=0
      this.dataList = [];
      that.control.haveMore = false;
      this.getDataList()
    },
    onCancelReg() {
      this.dataForm.page=0
      this.dataList = [];
      that.control.haveMore = false;
      this.show.registryType = '签约状态'
      this.dataForm.isSign = ''
      this.control.showRegistryType = false
      this.getDataList()
    },
    info(e){
      this.$router.push({path:'/signinfo',query:{data:e}})
    },
    onConTime(e) {
      console.log(e)
      let dates = formatterDate(e)
      var date = new Date(e);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      var d = date.getDate();
      if (m < 10) m = `0${m}` // 如果月份是个位数，在前面补0
      if (d < 10) d = `0${d}` // 如果日是个位数，在前面补0
      this.dataForm.startTime = dates
      this.control.showtimeType = false
      this.endDate=new Date(y,m-1,d)

      this.control.showtimeEndType = true

      // this.getDataList()
    },
    onCanceTime() {
      this.dataForm.page=0
      this.dataList = [];
      that.control.haveMore = false;
      this.dataForm.startTime = ''
      this.startTime=''
      this.dataForm.endTime=''
      this.show.time='签约时间'
      this.control.showtimeType = false
      this.getDataList()
    },

    onConTime1(e) {
      this.dataForm.endTime = formatterDate(e)
      this.control.showtimeEndType = false
      this.show.time=this.dataForm.startTime+'-'+this.dataForm.endTime
      this.dataForm.page=0
      this.dataList = [];
      that.control.haveMore = false;

      this.getDataList()
    },
    //结束时间取消  重新选择开始时间
    onCanceTime1() {
      this.dataForm.endTime=''
      this.endTime=''
      this.startTime=''
      this.dataForm.startTime = ''
      this.control.showtimeType = true
      this.control.showtimeEndType = false
      // this.getDataList()
    },
    getDataList() {
      this.DataPageLoading = false
      this.dataForm.page++
      if (that.dataForm.page == 1) {
        this.$toast.loading({
          duration: 0,
          message: '加载中...',
          forbidClick: true,
        });
      }
      this.$http({
        url: this.$http.adornUrl(`/wxapp/contract/user/list`),
        method: 'post',
        params: this.$http.adornParams(that.dataForm)
      }).then(({data}) => {
        that.loading = false

        that.$toast.clear();

        if (data.code == 0) {

          this.totalNum = data.page.totalCount
          let datas = data.page.list;
          let list = that.dataForm.page == 1 ? datas : that.dataList.concat(datas);
          let haveMore = list.length !== this.totalNum ? false : true;
          that.control.haveMore = haveMore
          that.dataList = list

        } else {
          that.$toast.fail(data.msg)
          that.control.haveMore = false;
        }
        this.DataPageLoading = true
        that.control.hasSubmit = false;
      })
    },

  }
}

</script>

<style lang="scss" scoped>
.filterBar {
  text-align: center;
  padding: 0 10px 0 0;
}

.van-grid-item__content {
  padding: 5px 0 !important;
}

.content-text {
  width: 450px !important;
}

.content-img {
  width: 120px !important;
  height: 120px !important;

  img {
    width: 120px !important;
    height: 120px !important;
  }
}

.van-popup {
  .van-tag {
    text-align: center;
    display: block;
    width: 190px;
    height: 70px;
    line-height: 70px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 10px;
  }
}

.imgg {
  width: 1.62rem;
  height: 1.62rem;
}

.buttons {
  font-size: 10px;
  border-radius: 0.12rem;
}

.tag-view {
  display: flex;
}

.wux {
  margin-left: 0.15rem;
}

.userFontSize {
  font-size: 30px;
}

.mini-font-size {
  font-size: 25px;
}




.van-col {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.list-search {
  display: flex;

  ::v-deep.van-ellipsis {
    color: #666666 !important;
    font-size: 24px;
    padding-top: 10px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::v-deep.van-dropdown-menu__bar {
    box-shadow: none;
  }
}

.cont {
  font-family: 'PingFangSC-Regular', serif;

  .top {
    margin-bottom: 16px;
    padding: 14px 30px 14px 30px;
    width: 750px;
    height: 320px;
    background: #FFFFFF;
    .totalCount {
      text-align: left !important;
      padding-left: 34px !important;
    }
    .button-box {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      gap: 42px;

      .createtime {

        font-weight: 400;
        font-size: 24px;
        color: #999999;
        line-height: 34px;
        text-align: left;
        font-style: normal;
      }

      .reminder {

        font-weight: 400;
        font-size: 24px;
        color: #E9564B;
        line-height: 34px;
        text-align: center;
        font-style: normal;
      }

    }

    .van-divider {
      margin: 0;
    }

    .org {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 72px;

      span {
        font-weight: 500;
        font-size: 30px;
        color: #666666;
        line-height: 42px;
        text-align: center;
        font-style: normal;
      }
    }

    .content {
      height: 228px;

      .van-divider {
        margin: 0;
      }

      .orgname {
        margin-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 52px;

        .span1 {
          font-weight: 600;
          font-size: 30px;
          color: #333333;
          line-height: 42px;
          text-align: left;
          font-style: normal;
        }

        .span2 {
          color: #4E7CF5;
          font-size: 30px;
          padding: 8px 20px 8px 20px;
          height: 52px;
          background: rgba(78, 124, 245, 0.12);
          border-radius: 8px;
        }
      }

      .user1 {
        margin-top: 30px;
      }

      .user {
        .QYtitle {
          width:400px;
          height: 42px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-family: 'PingFangSC-Regular', serif;
          font-weight: 600;
          font-size: 30px;
          color: #333333;
          line-height: 42px;
          text-align: left;
          font-style: normal;
        }

        display: flex;
        justify-content: space-between;
        margin-top: 14px;

        span {
          font-family: 'PingFangSC-Regular', serif;
          font-weight: 400;
          font-size: 28px;
          color: #666666;
          line-height: 40px;
          text-align: center;
          font-style: normal;

          .phone {
            font-family: 'PingFang Bold', serif;
            font-weight: 600;
            color: #333333;
          }
        }
      }
    }

  }
}

</style>
